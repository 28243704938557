<template>
  <v-container fluid>
    <page-title title="Полевое рисование">
      <template #description>
        <v-card-text
          style="color: black; white-space: pre-line"
          :class="{ 'body-2': $vuetify.breakpoint.xs }"
          class=" headline text-justify body-1 "
          ><span>
            Полевое рисование - это новый способ выстраивать нейронные связи, по-новому видеть
            жизненные ситуации и расширять мышление. На бумаге это выглядит как простой рисунок, без
            правил и необходимости уметь рисовать. Это одновременно и инструмент самокоучинга, и
            практика творческой рефлексии, и ваше персональное волшебство. Рисунок в этом методе -
            это материальное отражение того мира, который вы воспринимаете, на него можно
            воздействовать и перестраивать свою реальность. Трансформировать можно все - от
            состояния здоровья и отношений до судьбы в целом! Авторская разработка Надежды
            Навроцкой.</span
          ></v-card-text
        >
      </template></page-title
    >
    <list-component class="studios" :list="filterStudios" @createPath="createPath" />
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'SpaceDrawingList',
  //заменить - content!!!!!!!!
  metaInfo: {
    title: 'Полевое рисование',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: `Полевое рисование - это игровое пространство для самостоятельного решения психологических проблем`
      }
    ]
  },
  components: {
    ListComponent: () => import('@/components/shared/lists/ListComponent'),
    PageTitle: () => import('@/components/shared/PageTitle')
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      listStudios: state => state.studios.listStudios
    }),
    ...mapGetters(['filterDraftListStudios']),
    filterStudios() {
      return this.filterDraftListStudios.filter(studio => studio.secondType === 'spaceDrawing')
    }
  },
  created() {
    if (this.listStudios.length === 0) this.$store.dispatch('getListStudios', 'studios')
  },
  methods: {
    createPath(value) {
      if (this.user.payments && this.user.payments[value]) {
        this.$router.push(`/studios/home/${value}`)
      } else {
        this.$router.push(`/studios/preview/${value}`)
      }
    }
  }
}
</script>

<style scoped></style>
